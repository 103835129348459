import React from "react";
import styled from "styled-components";
import * as ReactScroll from "react-scroll";
import { withPage } from "../Page";
import IntroItem from "../Components/GeneralItem";
import * as L from "../Utils/Lang";
import { StaticFluidImage } from "../Components/StaticImage";

class ProductsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      tel: "",
      company: "",
      subject: "",
      description: ""
    };
  }
  render() {
    let { name, email, tel, company, subject, description } = this.state;
    return (
      <Wrapper banner={"images/products-banner.png"}>
        <div className="banner">
          <div className="blur-banner">
            <StaticFluidImage path="products-banner.png" />
          </div>
          <SloganWrapper>
            <Slogan large css={"margin-bottom: 30px;"}>
              IoT Connectivity Management
            </Slogan>
            <Slogan>
              TEL-U provides quick, secure and a worldwide IoT network
              availability to help your IoT business demands. It quickly
              associates your IoT devices to multiple mobile networks in more
              than 30 nations with just one service provider. It likewise
              empowers you to pick the best signal network accessible in a
              particular country. This guarantees you have the best worldwide
              coverage consistently.
            </Slogan>
          </SloganWrapper>
        </div>

        <ReactScroll.Link to="our-services" smooth={true} offset={-140}>
          {/* <ScrollIcon /> */}
        </ReactScroll.Link>

        <IntroWrapper>
          <div className="left">
            <TimelineBlock>
              <TimelineItem>
                <div className="dot" />
                <h3>Reliable, Consistent & Secure IoT Connectivity</h3>
                <p>
                  Providing Reliable, consistent & secure IoT connectivity are
                  the key factors for every IoT solution. To find the proper IoT
                  network management solutions for IoT implementations, that
                  depend on geographical regions and that require different
                  networks which will be complicated and time-consuming.
                </p>
              </TimelineItem>
              <TimelineItem>
                <div className="dot" />
                <h3>Avoid Complexity With Us</h3>
                <p>
                  And with IoT complexity, managing multiple carriers,
                  platforms, and contracts, it can be challenging and difficult
                  to get the expected outcome.
                </p>
              </TimelineItem>
              <TimelineItem>
                <div className="dot" />
                <h3>Single IoT Connectivity And Network Management</h3>
                <p>
                  With TEL-U single IoT connectivity and network management
                  platform that assists multiple carriers, your business has
                  access to a complete integrated, user-friendly interface for
                  ordering, activating, and managing wireless services for IoT
                  devices on global mobile networks.
                </p>
              </TimelineItem>
              <TimelineItem>
                <div className="dot" />
                <h3>Consolidate All Your Connections</h3>
                <p>
                  You will get very wide control when you consolidate all your
                  connections and IoT data plans across all networks available
                  from TEL-U. Real-time IoT connectivity and secure data
                  management, protect sensitive information and reduce risk to
                  your organizations.
                </p>
              </TimelineItem>
            </TimelineBlock>
          </div>
          <div className="right">
            <h1 style={{ color: "#86c7ff", margin: 40, textAlign: "center" }}>
              Our platform supplies high range IoT capabilities for:
            </h1>
            <Card src="../../images/bluepattern.png">
              <div className="card-container">
                <div className="card">
                  <div className="side">
                    <h1>Client Management</h1>
                  </div>
                  <div className="side back">Client Management</div>
                </div>
              </div>
            </Card>
            <Card src="../../images/greypattern.png">
              <div className="card-container">
                <div className="card">
                  <div className="side">
                    <h1>Personalized Business Intergration</h1>
                  </div>
                  <div className="side back">
                    Personalized Business Intergration
                  </div>
                </div>
              </div>
            </Card>
            <Card src="../../images/bluepattern.png">
              <div className="card-container">
                <div className="card">
                  <div className="side">
                    <h1>IOT security</h1>
                  </div>
                  <div className="side back">IOT security</div>
                </div>
              </div>
            </Card>
            <Card src="../../images/greypattern.png">
              <div className="card-container">
                <div className="card">
                  <div className="side">
                    <h1>Support technology</h1>
                  </div>
                  <div className="side back">Support technology</div>
                </div>
              </div>
            </Card>
          </div>
        </IntroWrapper>

        <IntroWrapper>
          <div className="left">
            <h1 style={{ color: "#86c7ff", margin: 40, textAlign: "center" }}>
              TEL-U eSIMs to hand over high class technology benefits.
            </h1>
            <Card src="../../images/bluepattern.png">
              <div className="card-container">
                <div className="card">
                  <div className="side">
                    <h1>Multiple Network Connectivity</h1>
                  </div>
                  <div className="side back">Multiple Network Connectivity</div>
                </div>
              </div>
            </Card>
            <Card src="../../images/greypattern.png">
              <div className="card-container">
                <div className="card">
                  <div className="side">
                    <h1>Independent User Surface</h1>
                  </div>
                  <div className="side back">Independent User Surface</div>
                </div>
              </div>
            </Card>
            <Card src="../../images/bluepattern.png">
              <div className="card-container">
                <div className="card">
                  <div className="side">
                    <h1>highly adaptable eSIM</h1>
                  </div>
                  <div className="side back">highly adaptable eSIM</div>
                </div>
              </div>
            </Card>
          </div>
          <div className="right">
            <TimelineBlock>
              <TimelineItem>
                <div className="dot" />
                <h3>Remote SIM Card Management Capabilities</h3>
                <p>
                  Our eSIM is fully supported and operable with any type of
                  mobile network, any SIM manufacturer and any device model.
                  Organizations are highly adaptable to eSIM solutions that
                  enable remote SIM card management capabilities to avoid IoT
                  solution life-cycle challenges associated with evolving
                  cellular technologies.
                </p>
              </TimelineItem>
              <TimelineItem>
                <div className="dot" />
                <h3>2G, 3G, LTE Internet service</h3>
                <p>
                  But some major carriers bought eSIM solutions to market their
                  value is restricted to each carrier’s specific capabilities
                  related to coverage area, technology compatibility (i.e. 2G,
                  3G, LTE, etc.), and other value-added services which restrain
                  them from adequately addressing the business challenges that
                  eSIM technologies are designed to mitigate.
                </p>
              </TimelineItem>

              <TimelineItem>
                <div className="dot" />
                <h3>ESIM Offering That Surpass The Traditional ESIM</h3>
                <p>
                  Due to the above defects, TEL-U has come up with a
                  comprehensive eSIM offering that exceed the traditional eSIM
                  offerings to deliver high class technology benefits.
                </p>
              </TimelineItem>
            </TimelineBlock>
          </div>
        </IntroWrapper>

        {/*
        ----------------------------------------------------------------------------------
         */}
        <ReactScroll.Element name="contact-us">
          <ContactUsWrapper>
            <h1
              style={{
                color: "white",
                margin: 40,
                padding: "50px 20px",
                textAlign: "center",
                backgroundColor: "#002F86"
              }}
            >
              contact us
            </h1>
            <Row>
              <TextInput>
                <p className="field">Your Name</p>
                <input
                  type="text"
                  value={name}
                  onChange={e => this.setState({ name: e.target.value })}
                />
              </TextInput>
              <TextInput>
                <p className="field">Your Email</p>
                <input
                  style={{ height: 40 }}
                  type="text"
                  value={email}
                  onChange={e => this.setState({ email: e.target.value })}
                />
              </TextInput>
            </Row>
            <Row>
              <TextInput>
                <p className="field">Contact #</p>
                <input
                  type="number"
                  value={tel}
                  onChange={e => this.setState({ tel: e.target.value })}
                />
              </TextInput>
              <TextInput>
                <p className="field">Company Name</p>
                <input
                  type="text"
                  value={company}
                  onChange={e => this.setState({ company: e.target.value })}
                />
              </TextInput>
            </Row>

            <TextInput>
              <p className="field">Your Subject</p>
              <input
                type="text"
                value={subject}
                onChange={e => this.setState({ subject: e.target.value })}
              />
            </TextInput>
            <TextInput>
              <p className="field">Description</p>
              <textarea
                type="text"
                value={description}
                onChange={e => this.setState({ description: e.target.value })}
              />
            </TextInput>
            <Btn>
              <a
                href={`
              mailto:van@mobiledatacompany.com?subject=${subject}
              &body=
              Sender name:%20${name}%0D%0A
              Sender email:%20${email}%0D%0A
              Sender tel:%20${tel}%0D%0A
              Sender company:%20${company}%0D%0A
              Sender name:%20${name}%0D%0A
              Description:%20${description}
            `}
              >
                Send Message
              </a>
            </Btn>
          </ContactUsWrapper>
        </ReactScroll.Element>
      </Wrapper>
    );
  }
}

let Wrapper = styled.div`
  background-color: black;
  & .blur-banner {
    @media screen and (max-width:700px) {
      display: none;
    } 
  }

  & > .banner {
    background-color: lightgray;
    position: relative;
    @media screen and (max-width:700px) {
      height: 90vh;
      background-image: url('${props => props.banner}');
      background-size: cover;
      background-repeat: none;
      background-position: center;
    } 
  }
`;

let Banner = styled.div`
        background-color: #ccc;
    background-image: url('${props => props.img}');
        background-size: cover;
        background-repeat: none;
        background-position: center;
        width: 100vw;
        min-height: 100vh;
        /* top: -90px; */
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    
    @media screen and (max-width:700px) {

        }
        `;

let Btn = styled.div`
  background-color: #002f86;
  padding: 12px 20px;
  margin: 15px;
  color: white;
  border-radius: 3px;
  text-align: center;
  max-width: 200px;
  cursor: pointer;
  a {
    text-decoration: none;
    color: white;
  }
  :hover {
    background-color: #2196f3;
  }
`;

let Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

let ContactUsWrapper = styled.div`
  padding: 30px;
  background-color: white;
`;

let TextInput = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: stretch;
  padding: 0px 15px;
  & > .field {
  }
  & > textarea {
    background-color: #f8f8f8;
    border: 1px solid #d0d0d0;
    height: 100px;
    border-radius: 3px;
    padding: 10px;
  }
  & > input {
    background-color: #f8f8f8;
    border: 1px solid #d0d0d0;
    height: 40px;
    border-radius: 3px;
    padding-left: 10px;
  }

  @media screen and (max-width: 700px) {
    & > input {
    }
  }
`;

let Card = styled.div`
  display: flex;
  margin: 20px;
  min-height: 200px;
  align-items: center;
  align-self: stretch;
  justify-content: center;
  border-radius: 10px;

  h1 {
    color: #002f86;
    text-align: center;
    font-size: 20px;
    margin-top: 55px; /* hard code */
  }

  .card-container {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: 150px;
    perspective: 600;
    position: relative;
    width: 300px;
  }
  .card {
    height: 100%;
    position: absolute;
    transform-style: preserve-3d;
    transition: all 0.5s ease-in-out;
    width: 100%;
  }
  .card:hover {
    transform: rotateY(180deg);
  }
  .card .side {
    backface-visibility: hidden;
    border-radius: 6px;
    height: 100%;
    position: absolute;
    overflow: hidden;
    width: 100%;
    /* background-image: url("../../images/bluepattern.png"); */
    background-image: url(${props => props.src});
  }
  .card .back {
    background-color: #eaeaea;
    color: #0087cc;
    line-height: 150px;
    text-align: center;
    transform: rotateY(180deg);
  }

  & > h1 {
    color: #86c7ff;
    text-align: center;
  }
`;

let TimelineBlock = styled.div`
  border-left: 3px solid #eee;
  margin: 30px;
`;

let TimelineItem = styled.div`
  position: relative;
  padding: 30px;
  & > h3 {
    font-size: 24px;
    color: #337ab7;
  }

  & > p {
    margin-top: 20px;
    color: lightgrey;
    line-height: 26px;
  }

  & > .dot {
    position: absolute;
    left: -12px;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background-color: black;
    border: 5px solid lightblue;
  }
`;

let SloganWrapper = styled.div`
  position: absolute;
  padding: 10px;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.5);
  top: 50%;
  left: 40%;
  transform: translate(-50%, -50%);

  @media screen and (max-width: 700px) {
    /* position: static;
    transform: none;
    padding: 20px;
    padding-top: 100px; */
    transform: translate(-50%, -40%);
  }
`;

let Slogan = styled.div`
  font-size: ${props => (props.large ? 40 : 18)}px;
  font-weight: ${props => (props.large ? 500 : 400)};
  color: ${props => (props.large ? "#418DB9" : "#fff")};
  line-height: ${props => (props.large ? 55 : 33)}px;
  letter-spacing: 1px;
  text-align: left;

  @media screen and (max-width: 700px) {
    font-size: ${props => (props.large ? 26 : 16)}px;
    line-height: ${props => (props.large ? 30 : 20)}px;
  }
  ${props => props.css}
`;

let IntroWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  & > .left {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
  }
  & > .right {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
  }
`;

export default withPage(ProductsPage);
